<template>
  <base-section id="lets-cooperate">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-highway"
      title="Pojďme spolupracovat"
      tag="h2"
      class="mt-n15"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Analýze Vašich potřeb',
          subtitle: 'Sejdeme se k',
          text: 'Rádi se potkáme k diskuzi o Vaší vizi. Zajímá nás, jakým způsobem Vám můžeme pomoci či poradit, máte-li nějaké předchozí zkušenosti a jaká máte očekávání. Pokud nemáte konkrétní představu, spolu ji určitě dokážeme dát reálné obrysy.',
          callout: '01',
        },
        {
          title: 'Nabídku na míru',
          subtitle: 'Vytvoříme vám',
          text: 'Na základě současné nejlepší praxe pro vás navrhneme optimální realizaci. Ať už se bavíme o jednom či desítkách vozidel, vždy pro Vás připravíme nabídku, která reflektuje Vaše přání a potřeby. A nebojte se, zpětnou vazbu vítáme s otevřenou náručí!',
          callout: '02',
        },
        {
          title: 'Podepíšeme smlouvu',
          subtitle: 'Doladíme detaily a',
          text: 'Pořádek dělá přátele. Formálně strvdíme nezbytnou dokumentaci a pak už jen čerpáte benefity naší spolupráce.',
          callout: '03',
        },
      ],
    }),
  }
</script>
